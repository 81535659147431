import React, { Component } from "react";

import "./css/styles.css";

class Welcome extends Component {
  constructor() {
    super();
    this.state = {};
  }

  navLinkClick = () => {
    if (document.querySelector(".menuBar ul").classList.contains("open")) {
      document.querySelector(".nav-toggler").click();
    }
  };

  onMenuClick = (menu = "welcomeContainer") => {
    const headerHeight = document.getElementById("header").offsetHeight;
    const welcomeHeight =
      document.getElementById("welcomeContainer").offsetHeight - headerHeight;

    switch (menu) {
      case "welcomeContainer":
        window.scrollTo(0, headerHeight);
        break;
      case "aboutUs":
        window.scrollTo(0, welcomeHeight);
        break;
      default:
        break;
    }
    this.navLinkClick();
  };

  render() {
    return (
      <div id="welcomeContainer" style={{ height: window.innerHeight }}>
        <h1>{"Engineering & Delivering Your Product."}</h1>
        <div id="continueBtnContainer">
          <section id="continueBtn" onClick={() => this.onMenuClick("aboutUs")}>
            <span></span>
          </section>
          <p id="continueText" onClick={() => this.onMenuClick("aboutUs")}>
            Continue
          </p>
        </div>
      </div>
    );
  }
}

export default Welcome;
