export const CONFIG = {
  SITE_NAME: "Kallsoft - Engineering & Delivering Your Product",
  VERSION: 1.1,
};
export const LABELS = {
  OUR_CLIENTS: "Our Clients",
  MEET_OUR_HAPPY_CLIENTS: "Meet our happy clients",
};
export const COLORS = {
  PRIMARY: "#800000",
  SECONDARY: "#daa520",
  ICON_COLOR: "#2A2A39",
  WHITE: "#FFF",
  RED: "red",
  BLACK: "#000",
  DIM_GRAY: "#CCC",
  PROGRESS_BAR_COLOR: "primary",
  PURPLE: "#6b6bde",
};
