import React, { Component } from "react";
import Button from "@material-ui/core/Button";

import { CONFIG } from "../../common/consts";

import "./css/styles.css";
import webLogo from "../../images/logo.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onPageScroll);
  }

  allEventListners = () => {
    // toggler icon click event
    document
      .querySelector(".menuIconBtn")
      .addEventListener("click", this.togglerClick);
    // nav links click event
    document
      .querySelectorAll(".menuBar a")
      .forEach((elem) => elem.addEventListener("click", this.navLinkClick));
  };
  // togglerClick function
  togglerClick = () => {
    document.querySelector(".menuIconBtn").classList.toggle("toggler-open");
    document.querySelector(".menuBar ul").classList.toggle("open");
  };
  // navLinkClick function
  navLinkClick = () => {
    if (document.querySelector(".menuBar ul").classList.contains("open")) {
      document.querySelector(".menuIconBtn").click();
    }
  };

  onPageScroll = () => {
    const headerHeight = document.getElementById("header").offsetHeight;
    const welcomeHeight =
      document.getElementById("welcomeContainer").offsetHeight - headerHeight;
    const aboutusHeight =
      welcomeHeight + document.getElementById("aboutUs").offsetHeight;
    const servicesHeight =
      aboutusHeight + document.getElementById("services").offsetHeight;
    const clientsHeight =
      servicesHeight + document.getElementById("clients").offsetHeight;
    //Menu bar
    if (window.scrollY >= clientsHeight) {
      this.toggleHeaderClass("contactUsMenu");
    } else if (window.scrollY >= servicesHeight) {
      this.toggleHeaderClass("ourClientMenu");
    } else if (window.scrollY >= aboutusHeight) {
      this.toggleHeaderClass("servicesMenu");
    } else if (window.scrollY >= welcomeHeight) {
      this.toggleHeaderClass("aboutusMenu");
    } else if (window.scrollY >= headerHeight) {
      this.toggleHeaderClass("homeMenu");
    } else {
      this.toggleHeaderClass();
    }
    //Scroll Up

    if (window.scrollY > welcomeHeight) {
      document.getElementById("scrollUpBtn").classList.add("visible");
    } else {
      document.getElementById("scrollUpBtn").classList.remove("visible");
    }
  };

  toggleHeaderClass = (clsName = "") => {
    // remove all menu classes
    document.getElementById("header").classList.remove("homeMenu");
    document.getElementById("header").classList.remove("aboutusMenu");
    document.getElementById("header").classList.remove("servicesMenu");
    document.getElementById("header").classList.remove("ourClientMenu");
    document.getElementById("header").classList.remove("contactUsMenu");
    if (clsName !== "") {
      document.getElementById("header").classList.add(clsName);
    }
  };

  onMenuClick = (menu = "welcomeContainer") => {
    const headerHeight = document.getElementById("header").offsetHeight;
    const welcomeHeight =
      document.getElementById("welcomeContainer").offsetHeight - headerHeight;
    const aboutusHeight =
      welcomeHeight + document.getElementById("aboutUs").offsetHeight;
    const servicesHeight =
      aboutusHeight + document.getElementById("services").offsetHeight;
    const clientsHeight =
      servicesHeight + document.getElementById("clients").offsetHeight;
    switch (menu) {
      case "welcomeContainer":
        window.scrollTo(0, headerHeight);
        break;
      case "aboutUs":
        window.scrollTo(0, welcomeHeight);
        break;
      case "services":
        window.scrollTo(0, aboutusHeight);
        break;
      case "clients":
        window.scrollTo(0, servicesHeight);
        break;
      case "contactUs":
        window.scrollTo(0, clientsHeight);
        break;
      default:
        break;
    }
    this.navLinkClick();
  };

  render() {
    return (
      <div id="header">
        <button
          className="scroll-top"
          id="scrollUpBtn"
          onClick={() => this.onMenuClick("welcomeContainer")}
        >
          <div className="arrow up"></div>
        </button>
        <div className="container" id="headerContainer">
          <div id="navBar">
            <div className="logo">
              <img
                src={webLogo}
                className="responsiveImg"
                alt={CONFIG.SITE_NAME}
              />
            </div>
            <div className="menuBar" id="menu">
              {/* <!-- site menu/nav --> */}
              <ul>
                <li>
                  <Button
                    className="menuBtn"
                    onClick={() => this.onMenuClick("welcomeContainer")}
                    size="small"
                    color="primary"
                  >
                    Home
                  </Button>
                </li>
                <li>
                  <Button
                    className="menuBtn"
                    onClick={() => this.onMenuClick("aboutUs")}
                    size="small"
                    color="primary"
                  >
                    About Us
                  </Button>
                </li>
                <li>
                  <Button
                    className="menuBtn"
                    onClick={() => this.onMenuClick("services")}
                    size="small"
                    color="primary"
                  >
                    Services
                  </Button>
                </li>
                <li>
                  <Button
                    className="menuBtn"
                    onClick={() => this.onMenuClick("clients")}
                    size="small"
                    color="primary"
                  >
                    Clients
                  </Button>
                </li>
                <li>
                  <Button
                    className="menuBtn"
                    onClick={() => this.onMenuClick("contactUs")}
                    size="small"
                    color="primary"
                  >
                    Contact Us
                  </Button>
                </li>
              </ul>
              {/* <!-- menuIconBtn for mobile version only --> */}
              <button className="menuIconBtn" onClick={this.allEventListners}>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
