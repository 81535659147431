import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { CONFIG } from "../../common/consts";

import img1 from "../../images/services/proto.png";
import img2 from "../../images/services/iot-development.png";
import img3 from "../../images/services/ai3.png";
import img4 from "../../images/services/cloud.png";
import img5 from "../../images/services/voip.png";
import img6 from "../../images/services/mobile-app-development.png";
import img7 from "../../images/services/img7.png";
// import img7 from "../../images/services/webde.png";

import "./css/styles.css";

class Services extends React.Component {
  componentDidMount() {
    AOS.init({
      duration: 1000,
    });
  }

  render() {
    return (
      <div id="services">
        <div className="container" data-aos="fade-down" data-aos-once="true">
          <div className="serviceContainer">
            <div className="textContent">
              <h2 className="heading">Our Services</h2>
              <p className="pText">
                We offer Product Engineering services with full NDA, source code
                and intellectual property rights and agreements. You can
                consider us as a development center for your products. We bring
                your ideas to life.
              </p>
            </div>
            <div className="imageBox">
              <img className="imgWidth" src={img7} alt={CONFIG.SITE_NAME} />
            </div>
          </div>
          <div id="subContainer">
            <div className="imageContainer">
              <div className="serviceContent" id="prototyping">
                <img src={img1} width="150px" alt={CONFIG.SITE_NAME} />
                <div className="imageContent">
                  <h4 className="subHead">Prototyping, R & D</h4>
                  <p className="imgText">
                    We help you with the research and prototypes and you can
                    develop the product idea further to perfection, the way it
                    is supposed to be.
                  </p>
                </div>
              </div>
              <div className="serviceContent" id="iot">
                <img src={img2} width="150px" alt={CONFIG.SITE_NAME} />
                <div className="imageContent">
                  <h4 className="subHead">
                    Scalable services and Distributed Applications
                  </h4>
                  <p className="imgText">
                    Cloudscale your product at ease. We will design, architect
                    and code your solution for cloud/hybrid environments. You
                    focus on the business, we will do the rest
                  </p>
                </div>
              </div>
              <div className="serviceContent" id="ai">
                <img src={img3} width="150px" alt={CONFIG.SITE_NAME} />
                <div className="imageContent">
                  <h4 className="subHead">AI/ML on the Cloud/Edge</h4>
                  <p className="imgText">
                    Need AI/ML solution for your problems? There is a high
                    chance that we may have already been there and done that. We
                    offer full end to end AI/ML pipeline suited to your
                    requirements
                  </p>
                </div>
              </div>
            </div>
            <div className="imageContainer" id="aws">
              <div className="serviceContent">
                <img src={img4} width="150px" alt={CONFIG.SITE_NAME} />
                <div className="imageContent">
                  <h4 className="subHead">AWS, GCP and Azure Services</h4>
                  <p className="imgText">
                    We offer cloud migration services for your on-premise
                    applications which is flexible, low TCO and cloudscale.
                  </p>
                </div>
              </div>
              <div className="serviceContent" id="webrtc">
                <img src={img5} width="150px" alt={CONFIG.SITE_NAME} />
                <div className="imageContent">
                  <h4 className="subHead">VoIP and WebRTC</h4>
                  <p className="imgText">
                    We offer SIP and WebRTC based Audio/Video solutions for all
                    the devices and PBX's. We also offer transcoding and
                    streaming solutions for your requirements
                  </p>
                </div>
              </div>
              <div className="serviceContent" id="mobileapps">
                <img src={img6} width="150px" alt={CONFIG.SITE_NAME} />
                <div className="imageContent">
                  <h4 className="subHead">Native Mobile Apps & Ecommerce</h4>
                  <p className="imgText">
                    We develop mobile apps and ecommerce sites which are secure
                    and user friendly by default.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
