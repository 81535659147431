import React, { Component } from "react";

import "./css/styles.css";

class PrivacyPolicy extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div id="privacyPolicy">
        <div className="container">
          <div className="effectiveDt">
            <p className="bold">Effective Date: </p>
            <p>December 1, 2021</p>
          </div>
          <div className="policyContainer">
            <h2 className="heading">Privacy Policy</h2>
            <h4 className="subHead">Introduction</h4>
            <p className="pText">
              This Privacy Policy describes our collection, use, and disclosure
              of personal information from United States residents and is
              intended for individuals in the United States. If you live outside
              of the United States and choose to use the websites or mobile
              applications connected with this Privacy Policy, you understand
              that your information will be sent to and stored in the United
              States. By using any of our websites and mobile applications
              (collectively, the “Sites”) or otherwise providing Personal
              Information to us, you indicate that you have read and understood
              this Privacy Policy. If you do not agree with our privacy
              practices as described in this Privacy Policy, please do not use
              the Sites.
            </p>
            <h5>Table of Contents:</h5>
            <p className="content">
              1. Categories of Personal Information We Collect
            </p>
            <p className="content">2. Sources of Personal Information</p>
            <p className="content">3. How We Use Personal Information</p>
            <p className="content">4. How We Share Personal Information</p>
            <p className="content">5. Cookies and Similar Technologies</p>
            <p className="content">
              6. How to Access and Control Your Personal Information
            </p>
            <p className="content">7. Additional Considerations</p>
            <div className="subContent">
              <h4 className="subHead">
                1. Categories of Personal Information We Collect
              </h4>
              <p className="content">
                We collect information that identifies, relates to, describes,
                references, is reasonably capable of being associated with, or
                could reasonably be linked, directly or indirectly, with a
                particular consumer, household or device (“Personal
                Information”).{" "}
              </p>
            </div>
            <div className="subContent">
              <h4 className="subHead">2. Sources of Personal Information</h4>
              <p className="content">
                We obtain the categories of Personal Information listed above
                from the following categories of sources:
              </p>
            </div>
            <div className="subContent">
              <h4 className="subHead">3. How We Use Personal Information</h4>
              <p className="content">
                We use Personal Information for the following business
                purposes:We may also use Personal Information as we believe to
                be necessary or appropriate for certain essential purposes,
                including If you submit any Personal Information relating to
                other people in connection with the Sites, you represent that
                you have the authority to do so and to permit us to use the
                information in accordance with this Privacy Policy.
              </p>
            </div>
            <div className="subContent">
              <h4 className="subHead">4. How We Share Personal Information</h4>
              <p className="content">
                We may disclose your Personal Information to third parties for
                business purposes. When we disclose Personal Information for a
                business purpose, we enter a contract that describes the purpose
                and requires the recipient to both keep that Personal
                Information confidential and not use it for any purpose except
                performing the contract.
              </p>
            </div>
            <div className="subContent">
              <h4 className="subHead">5. Cookies and Similar Technologies</h4>
              <p className="content">
                Coros uses cookies to record the preferences of visitors, to
                enable us to optimize the design of our Sites. Cookies are small
                files which are stored on your hard drive. They ease navigation,
                and increase the user-friendliness of a web site. Cookies also
                help us to identify the most popular sections of our Sites. This
                enables us to provide content that is more accurately suited to
                your needs, and in so doing improve our service. Cookies can be
                used to determine whether there has been any contact between us
                and your computer in the past. Only the cookie on your computer
                is identified. Personal details can be saved in cookies,
                provided that you have consented to this, for example, in order
                to facilitate secure online access so that you not need to enter
                your user ID and password again. Most browsers automatically
                accept cookies.
              </p>
            </div>
            <div className="subContent">
              <h4 className="subHead">
                6. How to Access and Control Your Personal Information
              </h4>
              <p className="content">
                Upon written request, Coros or its representatives will inform
                you by letter without delay what Personal Information is stored
                by us as defined by applicable law. Should such data be
                incorrect, we will at your request correct that information for
                you, you also have the option of viewing, deleting or amending
                your Personal Information. If you are a resident of the State of
                California and upon a verified request, you may request.
              </p>
            </div>
            <div className="subContent">
              <h4 className="subHead">7. Additional Considerations</h4>
              <h5>Careers</h5>
              <p className="content">
                The Sites may include a link to our Careers section. Any
                Personal Information submitted through that portion of the
                Sites, by upload or via e-mail, will be governed by our Job
                Applicant Privacy Agreement on our Careers page.
              </p>
              <h5>Security </h5>
              <p className="content">
                Coros uses reasonable technical and organizational security
                measures in order to protect the Personal Information we have
                under our control against accidental or intentional
                manipulation, loss, destruction and against access by
                unauthorized persons. Our security procedures are continually
                enhanced as new technology becomes available.
              </p>
              <h5>Other California Privacy Rights </h5>
              <p className="content">
                California's "Shine the Light" law (Civil Code Section §
                1798.83) permits users of our Sites that are California
                residents to request, once a year, certain information regarding
                our disclosure of Personal Information to third parties for
                their direct marketing purposes. We do not disclose Personal
                Information to third parties for their direct marketing
                purposes.
              </p>
              <h5>Do Not Track (DNT) </h5>
              <p className="content">
                At this time, we do not monitor or follow “Do Not Track” (DNT)
                signals.
              </p>
              <h5>Third Party Services</h5>
              <p className="content">
                Our Sites may contain links to other web sites or services on
                the internet which are owned and operated by third parties (the
                “Third Party Product(s)”). You acknowledge and agree that Coros
                is not responsible for the availability of, or the content
                located on or through, any Third Party Product.
              </p>
            </div>
            <div className="effectiveDt">
              <p className="bold">Last Review Date: </p>
              <p>December 1, 2021</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PrivacyPolicy;
