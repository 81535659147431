import React, { Component } from "react";
import Bugsnag from "@bugsnag/js";
import AOS from "aos";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import WorkIcon from "@material-ui/icons/Work";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";

import "./css/styles.css";
import "aos/dist/aos.css";

class ContactUs extends Component {
  componentDidMount() {
    AOS.init({
      duration: 1000,
    });
  }

  onChange = (value) => {
    console.log("Captcha value:", value);
  };

  onSubmit = (e) => {
    e.preventDefault();
    const uName = document.getElementById("uName").value.trim();
    const eMail = document.getElementById("eMail").value.trim();
    const contactNo = document.getElementById("contactNo").value.trim();
    const msg = document.getElementById("msg").value.trim();

    document.getElementById("uName").classList.remove("error");
    document.getElementById("eMail").classList.remove("error");
    document.getElementById("contactNo").classList.remove("error");
    document.getElementById("msg").classList.remove("error");

    let errorCount = 0;

    //Check for Empty
    if (uName === "") {
      document
        .querySelector('.textField[data-group="uName"]')
        .classList.add("errorMsg");
      errorCount++;
    } else {
      document
        .querySelector('.textField[data-group="uName"]')
        .classList.remove("errorMsg");
    }

    if (eMail === "") {
      document
        .querySelector('.textField[data-group="eMail"]')
        .classList.add("errorMsg");
      errorCount++;
    } else {
      document
        .querySelector('.textField[data-group="eMail"]')
        .classList.remove("errorMsg");
    }

    if (contactNo === "") {
      document
        .querySelector('.textField[data-group="contactNo"]')
        .classList.add("errorMsg");
      errorCount++;
    } else {
      document
        .querySelector('.textField[data-group="contactNo"]')
        .classList.remove("errorMsg");
    }

    if (msg === "") {
      document
        .querySelector('.textField[data-group="msg"]')
        .classList.add("errorMsg");
      errorCount++;
    } else {
      document
        .querySelector('.textField[data-group="msg"]')
        .classList.remove("errorMsg");
    }

    if (errorCount > 0) {
      return false;
    }

    // Check for Phone number
    if (isNaN(contactNo)) {
      document
        .querySelector('.textField[data-group="contactNo"]')
        .classList.add("errorMsg");
      errorCount++;
    }

    // Check for Email

    if (errorCount > 0) {
      return false;
    }

    let enquiryStr = "New Enquiry: Name: " + uName + ", ";
    enquiryStr += "Phone: " + contactNo + ", ";
    enquiryStr += "Email: " + eMail + ", ";
    enquiryStr += "Message: " + msg + " ";
    Bugsnag.notify(enquiryStr, this.onEnquiryCb);
    return false;
  };

  onEnquiryCb = (e, f) => {
    document.getElementById("frmResult").innerText =
      "Enquiry sent successfully, We'll get back to you soon....";
  };

  render() {
    return (
      <div id="contactUs">
        <div className="container" data-aos="fade-down" data-aos-once="true">
          <h2 className="heading white">Contact Us</h2>
          <h4 className="subHeading white">
            Have any queries? We'd love to here from you..
          </h4>
          <div className="contactContainer">
            <div className="content">
              <div className="detailsContainer">
                <div className="details">
                  <LocationOnIcon className="icon" />
                  <h4 className="">Address</h4>
                  <p>#356(1-17), Utsava Building,</p>
                  <p>Pakshikere, Mangalore 574 146</p>
                </div>
                <div className="details">
                  <WorkIcon className="icon" />
                  <h4 className="">Working hours</h4>
                  <p className="">Open Monday to Saturday</p>
                  <p className="">From 09:00 AM to 6:00 PM</p>
                </div>
              </div>
              <div className="detailsContainer">
                <div className="details">
                  <CallIcon className="icon" />
                  <h4 className="">Phone</h4>
                  <a className="link" href="tel:+91-99863 98327">
                    +91-99863 98327
                  </a>
                </div>
                <div className="details">
                  <EmailIcon className="icon" />
                  <h4 className="">Email</h4>
                  <a
                    className="link"
                    href="mailto:sales@kallsoft.net"
                    style={{ paddingBottom: 20 }}
                  >
                    sales@kallsoft.net
                  </a>
                </div>
              </div>
            </div>
            <div className="inputContainer">
              <div className="width100">
                <h3>GET IN TOUCH</h3>
              </div>
              <form onSubmit={this.onSubmit}>
                <label id="frmResult"></label>
                <div className="textField" data-group="uName">
                  <TextField
                    id="uName"
                    className="width100"
                    label="Name"
                    // required
                  />
                  <p className="">* Please enter your name</p>
                </div>
                <div className="textField" data-group="eMail">
                  <TextField
                    id="eMail"
                    label="Email"
                    className="width100"
                    // type="email"
                    // required
                  />
                  <p>* Please enter proper email address</p>
                </div>
                <div className="textField" data-group="contactNo">
                  <TextField
                    id="contactNo"
                    label="Contact No"
                    className="width100"
                    // type="number"
                    // required
                  />
                  <p id="demo">* Phone number should be 10 digits</p>
                </div>
                <div className="textField" data-group="msg">
                  <TextField
                    multiline
                    id="msg"
                    className="width100"
                    label="Message"
                    // required
                  />
                  <p>* Please enter your message</p>
                </div>
                <div className="captchaFeild">
                  <ReCAPTCHA sitekey="6LcJcGsdAAAAAB7-RCE8vsrNOLMt19asrDPMNBgX" />
                </div>
                <div className="btnContainer">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className="btn"
                    type="submit"
                    value="Submit"
                  >
                    submit
                  </Button>
                </div>
              </form>
              {/* <div className="mapContainer">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.7777009627516!2d74.81852865080668!3d13.04981719025992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5267bc124269645!2sVaschan%20telecom!5e0!3m2!1sen!2sin!4v1637905139815!5m2!1sen!2sin"
                  width="100%"
                  height="350"
                  style={{ border: "none" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
