import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";

import { CONFIG, LABELS } from "../../common/consts";

import client1 from "../../images/clients/client1.png";
import client2 from "../../images/clients/client2.png";
import client3 from "../../images/clients/client3.png";
import client4 from "../../images/clients/client4.png";
import client5 from "../../images/clients/client5.png";
import client6 from "../../images/clients/client6.png";
import client7 from "../../images/clients/client7.png";
import client8 from "../../images/clients/client8.png";

import "./css/styles.css";

class Clients extends Component {
  componentDidMount() {
    AOS.init({
      duration: 1500,
    });
  }

  render() {
    var settings = {
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      arrows: false,
      dots: false,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div id="clients">
        <div className="container" data-aos="fade-down" data-aos-once="true">
          <div id="clientsHeader">
            <h2 className="heading">{LABELS.OUR_CLIENTS}</h2>
            <h4 className="subHeading">{LABELS.MEET_OUR_HAPPY_CLIENTS}</h4>
          </div>
          <div className="clientList">
            <Slider {...settings}>
              <div className="clientProfile">
                <img src={client1} alt={CONFIG.SITE_NAME} />
              </div>
              <div className="clientProfile">
                <img src={client2} alt={CONFIG.SITE_NAME} />
              </div>
              <div className="clientProfile">
                <img src={client3} alt={CONFIG.SITE_NAME} />
              </div>
              <div className="clientProfile">
                <img src={client4} alt={CONFIG.SITE_NAME} />
              </div>
              <div className="clientProfile">
                <img src={client5} alt={CONFIG.SITE_NAME} />
              </div>
              <div className="clientProfile">
                <img src={client6} alt={CONFIG.SITE_NAME} />
              </div>
              <div className="clientProfile">
                <img src={client7} alt={CONFIG.SITE_NAME} />
              </div>
              <div className="clientProfile">
                <img src={client8} alt={CONFIG.SITE_NAME} />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default Clients;
