import React, { Component } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { CONFIG } from "../../common/consts";

import img1 from "../../images/aboutus/img1.png";
import img2 from "../../images/aboutus/img2.png";
import img3 from "../../images/aboutus/img3.png";
import img4 from "../../images/aboutus/img4.png";
import img5 from "../../images/aboutus/img5.png";

import "./css/styles.css";

class AboutUs extends Component {
  componentDidMount() {
    AOS.init({
      duration: 1000,
    });
  }
  render() {
    return (
      <div id="aboutUs">
        <div className="container" data-aos="fade-down" data-aos-once="true">
          <div className="content">
            <h2 className="heading">About Us</h2>
            <p className="pText">
              Kallsoft began it's journey in 2010. Since then we have delivered
              quality software as customer requirements on the dot. Along the
              way, we have accumulated great amount of experience in high
              performance servers, lightweight frontend and applications that
              perform across devices and platforms. Over the years, we have
              solved every challenge that is thrown at us with ease.
            </p>
            <p className="pText">
              Our skillset and domain expertise are in several domains ranging
              from retail to AI/ML. We have extensive delivery experience in
              VoIP, WebRTC, IoT and AI/ML. In total, the team is capable and
              expert level in over 75 technologies across backend, frontend,
              testing and QA. Collectively we have several thousand hours of
              full SDLC.
            </p>
            <p className="pText">
              All our applications are developed over rock solid frameworks and
              thus modular and extensible by default. Security first is in our
              blood. There is no application or library that goes into
              production without infosec and rigorous quality analysis. Some of
              our applications are running in production since 8 years without a
              single restart and we find honour, satisfaction in our work.
            </p>
          </div>
          <div className="imageContainer">
            <div>
              <img
                src={img3}
                className="fadeInDownImg"
                alt={CONFIG.SITE_NAME}
              />
            </div>
            <div>
              <img
                src={img4}
                className="fadeInDownImg"
                alt={CONFIG.SITE_NAME}
              />
            </div>
            <div>
              <img
                src={img1}
                className="fadeInDownImg"
                alt={CONFIG.SITE_NAME}
              />
            </div>
            <div>
              <img
                src={img5}
                className="fadeInDownImg"
                alt={CONFIG.SITE_NAME}
              />
            </div>
            <div>
              <img
                src={img2}
                className="moveUpDownImg"
                alt={CONFIG.SITE_NAME}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
