import React, { Component } from "react";

class Error404 extends Component {
  render() {
    return (
      <div style={{ height: "100%", display: "flex", backgroundColor: "#FFF" }}>
        <h1 style={{ margin: "auto", fontSize: "60px" }}>
          Error 404: Page Not Found !
        </h1>
      </div>
    );
  }
}

export default Error404;
