import React, { Component } from "react";

import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Tooltip from "@material-ui/core/Tooltip";

import { CONFIG } from "../../common/consts";

import webLogo from "../../images/logo.png";
import duns from "../../images/footer/duns.jpg";

class Footer extends Component {
  constructor() {
    super();
    this.state = {};
  }

  navLinkClick = () => {
    if (document.querySelector(".menuBar ul").classList.contains("open")) {
      document.querySelector(".nav-toggler").click();
    }
  };

  onMenuClick = (menu = "welcomeContainer") => {
    const headerHeight = document.getElementById("header").offsetHeight;
    const welcomeHeight =
      document.getElementById("welcomeContainer").offsetHeight - headerHeight;
    const aboutusHeight =
      welcomeHeight + document.getElementById("aboutUs").offsetHeight;
    const servicesHeight =
      aboutusHeight + document.getElementById("services").offsetHeight;
    const clientsHeight =
      servicesHeight + document.getElementById("clients").offsetHeight;
    switch (menu) {
      case "welcomeContainer":
        window.scrollTo(0, headerHeight);
        break;
      case "aboutUs":
        window.scrollTo(0, welcomeHeight);
        break;
      case "services":
        window.scrollTo(0, aboutusHeight);
        break;
      case "clients":
        window.scrollTo(0, servicesHeight);
        break;
      case "contactUs":
        window.scrollTo(0, clientsHeight);
        break;
      default:
        break;
    }
    this.navLinkClick();
  };

  render() {
    return (
      <div id="footer" style={{ backgroundColor: "black" }}>
        <div className="container">
          <div className="footerContainer">
            <div className="footerContent mT23">
              <ul>
                <li>
                  <h4 className="subHeading">Kallsoft</h4>
                </li>
                <li>
                  <p>
                    #356(1-17), Utsava Building, Pakshikere, Mangalore 574 146
                  </p>
                </li>
                <li>
                  <a className="link" href="mailto:sales@kallsoft.net">
                    sales@kallsoft.net
                  </a>
                </li>
                <li>
                  <a className="link" href="tel:+91-99863 98327">
                    (+91) 9986 398 327
                  </a>
                </li>
              </ul>
              <div id="iconContainer">
                <a href="" target="_blank">
                  <Tooltip title="Twitter" placement="top">
                    <TwitterIcon />
                  </Tooltip>
                </a>
                <a href="" target="_blank">
                  <Tooltip title="Instagram" placement="top">
                    <InstagramIcon />
                  </Tooltip>
                </a>
                <a href="" target="_blank">
                  <Tooltip title="Facebook" placement="top">
                    <FacebookIcon />
                  </Tooltip>
                </a>
                <a href="" target="_blank">
                  <Tooltip title="LinkedIn" placement="top">
                    <LinkedInIcon />
                  </Tooltip>
                </a>
              </div>
            </div>
            <div className="footerContent">
              <ul>
                <li>
                  <h4 className="subHeading">IT Services</h4>
                </li>
                <li>
                  <a href="#prototyping">Prototyping, R & D</a>
                </li>
                <li>
                  <a href="#iot">Large Scale IoT and Sensor Networks</a>
                </li>
                <li>
                  <a href="#ai">AI/ML on the Cloud/Edge</a>
                </li>
                <li>
                  <a href="#aws">AWS, GCP and Azure Services</a>
                </li>
                <li>
                  <a href="#webrtc">VoIP and WebRTC</a>
                </li>
                <li>
                  <a href="#mobileapps">Native Mobile Apps & Ecommerce</a>
                </li>
              </ul>
            </div>
            <div className="footerContent">
              <ul>
                <li>
                  <h4 className="subHeading">Quick links</h4>
                </li>
                <li onClick={() => this.onMenuClick("welcomeContainer")}>
                  Home
                </li>
                <li onClick={() => this.onMenuClick("aboutUs")}>About Us</li>
                <li onClick={() => this.onMenuClick("services")}>Services</li>
                <li onClick={() => this.onMenuClick("clients")}>Clients</li>
                <li onClick={() => this.onMenuClick("contactUs")}>
                  Contact Us
                </li>
                <a href="/privacy-policy">
                  <li style={{ listStyle: "none" }}>Privacy Policy</li>
                </a>
              </ul>
            </div>
            <div className="footerContent webLogo">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <img src={duns} width="150px" style={{ marginRight: "20px" }} />
                <img src={webLogo} width="135px" alt={CONFIG.SITE_NAME} />
              </div>
            </div>
          </div>
          <div id="divider"></div>
          <div id="bottomContent">
            <p>
              &copy; Copyright Kallsoft <span>{new Date().getFullYear()}.</span>{" "}
              All rights reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
