import React, { Component } from "react";

import Header from "../Layouts/Header";
import Welcome from "../Welcome";
import AboutUs from "../AboutUs";
import Services from "../Services";
import Clients from "../Clients";
import ContactUs from "../ContactUs";
import Footer from "../Layouts/Footer";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <Welcome />
        <AboutUs />
        <Services />
        <Clients />
        <ContactUs />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
